import React, { useState, useEffect, useRef } from "react";
import { CTX } from "../components/Store";
import Modal from "react-modal";
import { addMonths, yyyymmdd, signs, pAbr, sAbr, a_names, modalSmall, modalBig, mod, deg2Sgn, fmtDays, getLunarPhase, valDt } from './util';
import {Icon} from "./Icon";
import { v4 as uuidv4 } from 'uuid';
import { IconVCRF, IconVCRB, IconVCRBMax, IconVCRFMax} from './svgIcons';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import Aspects from "./Aspects";
import Interps from "./Interps";
import Timeline from "./Timeline";
import heartred from '../img/heart-icon-red.svg'
import heartgrey from '../img/heart-icon-grey.svg'
import geargold from '../img/gear-icon-gold.svg'
import geargrey from '../img/gear-icon-grey.svg'

Modal.setAppElement('#root')

export default function Charts() {
  const burl = 'https://www.spiritguideastrology.com';
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])

  const [isOpen, setIsOpen] = useState(false);
  const [ptype, setPtype] = useState('');
  const [pdays, setPdays] = useState('');
  const [pevtsCur, setPevtsCur] = useState(0);
  const [pevtsMax, setPevtsMax] = useState(0);
  const [tabIndex, setTabIndex] = useState(0);
  const [defval, setDefval] = useState("");
  const [opts, setOpts] = useState([]);
  const [tlind, setTlind] = useState(false);
  const [dtepos, setDtePos] = useState(`translate(10, 15)`);
  const [evtpos, setEvtPos] = useState(`translate(10, 57)`);
  const [hsex1] = useState([]);
  const [hsey1] = useState([]);
  const [hsex2] = useState([]);
  const [hsey2] = useState([]);
  const [wrot, setWrot] = useState(`rotate(0  213 220 )`);
  const [hse_pos_min] = useState([]);
  const [hse_min] = useState([]);
  const [pord] = useState([]);
  const [pl_dsm] = useState([
    [0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100],
  [0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100],[0, -100, -100]]);
  const [pl_pos] = useState([]);
  const [pl_rtro] = useState([]);
  const [pl_decl] = useState([]);
  const [pl_pos_deg] = useState([]);
  const [pl_pos_min] = useState([]);
  const [pl_pos_glyph] = useState([]);
  const [plsrt, setPlsrt] = useState([]);
  const [hse_glyph] = useState([]);
  const [hse_sgn] = useState([]);
  const [hse_pos] = useState([]);
  const [hse_pos_deg] = useState([]);
  const [hse_deg] = useState([]);
  const [nat_deg] = useState([]);
  const [nat_min] = useState([]);

  // predictive
  const [ppord] = useState([]);
  const [ppl_dsm, setPpl_dsm] = useState([]);
  const [ppl_pos, setPpl_pos] = useState([]);
  const [ppl_rtro, setPpl_rtro] = useState([]);
  const [ppl_decl, setPpl_decl] = useState([]);
  const [ppl_pos_deg, setPpl_pos_deg] = useState([]);
  const [ppl_pos_min, setPpl_pos_min] = useState([]);
  const [pp_degmin, setPp_degmin] = useState([]);
  const [ppl_pos_glyph, setPpl_pos_glyph] = useState([]);
  const [pplsrt, setPplsrt] = useState([]);
  const [sunh60, setSunh60] = useState(-1);
  const [sunh360, setSunh360] = useState(-1);

  const [asp_dt, setAsp_dt] = useState('');
  const [pType, setPType] = useState('Natal');
  const [pLPhase, setPLPhase] = useState('');
  const [plAspp, setPlAspp] = useState('');
  const [plAspa, setPlAspa] = useState('');
  const [plAspn, setPlAspn] = useState('');
  const [plAspnT, setPlAspnT] = useState('');
  const [pEvent, setPEvent] = useState('');
  const [maincname, setMcliname] = useState("");
  const messageRef = useRef("");
  // https://refine.dev/blog/react-svg/#what-is-svg
  const clientnameRef = useRef("");
  const mainclinmeRef = useRef("X");
  const [prg_hse, setPrg_hse] = useState(-1);
 // const [dispAll, setDispAll] = useState(true);
  //let [lookupname, setLookupname] = useState('');
  let [fromdt, setFromdt] = useState('');
  let [todt, setTodt] = useState('');

  const xctr = 213;
  const yctr = 220;
  const rad = 180;
  const mod_ptype = React.createRef(null);

  const btnsm = {
    minWidth: "100px"
  };

useEffect(() => {
  let h5 = sunh60;
  let lp = pLPhase;
  forceUpdateComponent();
}, [sunh60, pLPhase]);

// natal planets / hses
useEffect(() => {
  setTabIndex(0);
  setPEvent("");
  set_def_dts();
  setPlAspp('');
  setPlAspa('');
  setPlAspn('');
  setPlAspnT('');
  setPEvent('');
  setAsp_dt('');
  setPdays('');

  if (st.name !== '') {
      document.getElementById("inp01").value = st.name;
  } else {
      document.getElementById("inp01").value = "";
    //setLookupname(st.name);
  }

  document.getElementById("combonme").innerHTML = "";
  // houses
  if (st.hses.length > 0 && st.lng.length > 0 ){

    // rotate chart to asc
    const asc = st.hses[1];
    setWrot(`rotate(${asc} ${xctr} ${yctr} )`);

    // clear predictive settings
    setPpl_dsm([]);
    setPpl_pos([]);
    setPpl_rtro([]);
    setPpl_pos_deg([]);
    setPpl_pos_min([]);
    setPp_degmin([]);
    setPpl_pos_glyph([]);
    setPplsrt([]);
    setPevtsCur(st.pidx);
    setPType('Natal');
    setTlind(false);
    setDtePos(`translate(10, 10)`);
    setEvtPos(`translate(10, 57)`);
    st.plng = [];
    st.prto = [];
    st.pevts = [];
    st.tl_sel = '';
    let h5 = Math.floor(((Math.floor(st.lng[0]) / 6)) % 12);
    setSunh60(h5);
    let h30 = (Math.floor(st.lng[0]) % 12);
    setSunh360(h30);
    setPLPhase('');
    //st.tlines = [];

    // clear and load sort
    setPlsrt([]);
    for(let j = 0 ; j < 12; j++){
      hse_sgn[j] = deg2Sgn(st.hses[j]);
      let oob = false;
      if (Math.abs(st.decl[j]) >= 23.0) {
         oob = true;
      }
      plsrt.push({
        lng: st.lng[j],
        rtro: st.rtro[j],
        decl: oob,
        pnbr: j
      });
    }

    // sort planets by longitude
    plsrt.sort((a, b) => {
      return a.lng - b.lng;
    });

    // house x/y coordinates
    for (let j = 0; j < 6; j++) {
      [hsex1[j], hsey1[j]] = inWheel(
        xctr, yctr, rad * 0.8888, 360 - mod(360, st.hses[j]));

      [hsex2[j], hsey2[j]] = inWheel(
        xctr,  yctr,  rad * 0.8888, 360 - mod(360, st.hses[j + 6]));
    }

    // house positions glyphs deg min
    for(let i = 0 ; i < 12; i++){
      hse_glyph[i] = signs[hse_sgn[i][0]];

      const x01 = inWheel(
        xctr -20, yctr -20, rad * 0.9444,
        180 - mod(360, st.hses[i] - st.hses[1]));
        hse_pos[i] = `translate(${x01[0]} ${x01[1]} ) scale(.8)`;

      // hse cusp deg
      const x02 = inWheel(
        xctr - 5, yctr + 3.5, rad * 0.95,
        180 - mod(360, st.hses[i] - asc + 5)
      );
      hse_pos_min[i]=`translate(${x02[0]} ${x02[1]})`;

      // hse cusp min
      const x03 = inWheel(
        xctr - 5, yctr + 3.5, rad * 0.95,
        180 - mod(360, st.hses[i] - asc - 5)
      );
      hse_pos_deg[i]=`translate(${x03[0]} ${x03[1]})`;

      if (i < 6) {
        hse_deg[i] = Math.floor(mod(30, st.hses[i])) + '';
        hse_min[i] = Math.round((st.hses[i] % 1) * 60) + '';
        hse_min[i+6] = Math.floor(mod(30, st.hses[i])) + '';
        hse_deg[i+6] = Math.round((st.hses[i] % 1) * 60) + '';
      }
    }

    // push planets apart if they overlap
    let plprv = plsrt[0];
    for (let j = 1; j < plsrt.length; j++) {
      if (plsrt[j].lng - plprv.lng < 5) {
        plsrt[j].lng = plsrt[j - 1].lng + 5;
      }
      plprv = plsrt[j];
    }

    // position/config nat planets
    for (let p = 0; p < plsrt.length; p++) {
      pord[p] = plsrt[p].pnbr;
      pl_dsm[p] = deg2Sgn(st.lng[plsrt[p].pnbr]);
      pl_rtro[p] = plsrt[p].rtro;
      pl_decl[p] = plsrt[p].decl;

      // natal planet position
      const na_offset = 180 - mod(360, plsrt[p].lng - asc);

      let x04 = inWheel(
        xctr - 12, yctr - 12, rad *  0.82, na_offset);
      pl_pos[p]=`translate(${x04[0]} ${x04[1]})scale(.4)`;

      // natal planet degree/minute
      nat_deg[p] = Math.floor(mod(30, st.lng[plsrt[p].pnbr])) + '';
      nat_min[p] = Math.round((st.lng[plsrt[p].pnbr] % 1) * 60) + '';

      // planet sign glyph
      const x05 = inWheel(
        xctr -19, yctr -21, rad * 0.63, na_offset );
      pl_pos_glyph[p]=`translate(${x05[0]} ${x05[1]}) scale(.8)`;

      // planet degree
      const x06 = inWheel(
        xctr - 3, yctr, rad * 0.72, na_offset);
      pl_pos_deg[p]=`translate(${x06[0]} ${x06[1]})`;

      // planet minute
      const x07 = inWheel(
        xctr - 3, yctr, rad * 0.527, na_offset );
      pl_pos_min[p]=`translate(${x07[0]} ${x07[1]})`;
    }

    if (st.ptype === 'lp' || st.ptype === 'sa') {
      setPLPhase('');
    } else {
      const prglp = getLunarPhase(st.ptype, st.lng[0], st.lng[1]);
      const prglp2 = prglp.split("-");
      setPLPhase(prglp2[0]);
      setPrg_hse(prglp2[1] - 1);
    }

  } else {
    st.tlines = [];
    st.st_sel = '';
  }
  if (st.tlines.length > 0) {
    let list = st.tlines.map((item, index) => {
      return (
        <option key={uuidv4()} value={item}>{item}</option>
      )
    })
    setOpts(list);
    setDefval("");
  } else {
    setOpts([])
  }
  forceUpdateComponent();
  }, [st.lng]);

// predictive planets / hses
useEffect(() => {

  if (st.plng && st.plng.length > 0 ){
    if (document.getElementById('heart').src == burl + heartred) {
        document.getElementById("combonme").innerHTML = st.from;
    } else {
        document.getElementById("combonme").innerHTML = "";
    }

    // set cur event #
    setPevtsCur(st.pidx);
    let max = st.pevts.length > 0 ? st.pevts.length -1: 0;
    setPevtsMax(max);

    const asc = st.hses[1];

    // force red mars on SRetro
    if (st.ptype === 'p3mar'){
      if (st.pevts[pevtsCur].e === 'SRetro' ){
        st.prtro[4] = true;
      }
      if (st.pevts[pevtsCur].e === 'SDirect' ){
        st.prtro[4] = false;
      }
    }
    // omit asc/mc for tr and lun
    // omit dec/ic for p32p3
    let lngmax = (st.ptype === 'lp' || st.ptype === 'tr' || st.ptype === 't2t' || st.ptype === 'p32p3') ? st.plng.length -2 : st.plng.length;

    if ((document.getElementById('heart').src == burl + heartred) && (st.ptype === 'tr')) {
	lngmax = st.plng.length;
    }
    // clear and load sort
    setPplsrt([]);
    for(let j = 0 ; j < lngmax; j++){
      // predict planet degree/minute
      pplsrt.push({
        lng: st.plng[j],
        rtro: st.prtro[j],
        decl: st.decl[j],
        pnbr: j
      });
    }

    pplsrt.sort((a, b) => {
      return a.lng - b.lng;
    });

    // push predict planets apart if they overlap
    let pplprv = pplsrt[0];
    for (let j = 1; j < pplsrt.length; j++) {
      if (pplsrt[j].lng - pplprv.lng < 5) {
        pplsrt[j].lng = pplsrt[j - 1].lng + 5;
      }
      pplprv = pplsrt[j];
    }

    // position/config pred planets
    for (let pp = 0; pp < pplsrt.length; pp++) {
      ppord[pp] = pplsrt[pp].pnbr;
      ppl_rtro[pp] = pplsrt[pp].rtro;
      ppl_decl[pp] = pplsrt[pp].decl;

      let insrt = deg2Sgn(st.plng[pplsrt[pp].pnbr]);
      ppl_dsm[pp] = insrt;
      pp_degmin[pp] = mod(30, ppl_dsm[pp][1]) + '°' +
        mod(60, ppl_dsm[pp][2]);

      const poffset = 180 - mod(360, pplsrt[pp].lng - asc)

      // predict planet position
      let p01 = inWheel(
        xctr - 12, yctr - 12, rad * 1.06, poffset);
      ppl_pos[pp]=`translate(${p01[0]} ${p01[1]})scale(.36)`;

      // pplanet sign glyph
      const p02 = inWheel(
        xctr -12, yctr -12, rad * 1.3, poffset);
      ppl_pos_glyph[pp]=`translate(${p02[0]} ${p02[1]}) scale(.6)`;

      // pplanet minute
      const p03 = inWheel(
        xctr -5, yctr, rad * 1.23, poffset);
      ppl_pos_min[pp]=`translate(${p03[0]} ${p03[1]})`;

      // pplanet degree
      const p04 = inWheel(
        xctr - 4, yctr + 4, rad * 1.16, poffset);
      ppl_pos_deg[pp]=`translate(${p04[0]} ${p04[1]})`;
    }

    if(st.pevts.length > 0) {
      setAsp_dt(st.pevts[st.pidx].d);

      //if (st.pevts[st.pidx].a === 0 &&
      if (st.pevts[st.pidx].f === 0 ) {
          setTlind(true);
          setDtePos(`translate(10, 32)`);
          setEvtPos(`translate(10, 10)`);
        } else {
          setTlind(false);
          setDtePos(`translate(10, 10)`);
          setEvtPos(`translate(10, 57)`);
      }

      if (st.ptype !== 'p3mar' &&
      st.ptype !== 'p3ven' &&
      st.ptype !== 'p3mer' &&
      st.ptype !== 'p3all') {
        setPdays(fmtDays(st.pevts[st.pidx].s));
      }

      if (st.days === "0") {
        setPEvent(st.pevts[st.pidx].e);
        if (pEvent.startsWith("P3")) {
          setPEvent(pEvent.substring(5));
        }
      }

      if (st.ptype === 'mp') {
          setTlind(true);
          setPEvent("");
      }

      if (st.ptype === 'p2' || st.ptype === 'na' || st.ptype === 'tr' || st.ptype === 'lp') {
          let h5 = Math.floor(((Math.floor(st.plng[0]) / 6)) % 12);
          setSunh60(h5);
          let h30 = (Math.floor(st.plng[0]) % 12);
          setSunh360(h30);
      } else {
          setSunh60(-1);
          setSunh360(-1);
      }

      if (st.ptype === 'lp' || st.ptype === 'sa') {
        setPLPhase('');
      } else {
	let prglp = '';
	if (st.ptype === 'na' ) {
          prglp = getLunarPhase(st.ptype, st.lng[0], st.lng[1]);
	} else {
          prglp = getLunarPhase(st.ptype, st.plng[0], st.plng[1]);
	}
        const prglp2 = prglp.split("-");
        setPLPhase(prglp2[0]);
        setPrg_hse(prglp2[1] - 1);
      }

      // set display pred type
      switch (st.ptype) {
        case 'tr':
          setPType('Transits');
          break;
        case 'tr100':
          setPType('100 Days');
          break;
        case 't2t':
          setPType('Tran2Tran');
          break;
        case 'lp':
          setPType('Lunations');
          break;
        case 'sa':
          setPType('Solar Arcs');
          break;
        case 'p2':
          setPType('Secondaries');
          break;
        case 'p3':
          setPType('Tertiaries');
          break;
        case 'mp':
          setPType('S2 Moon');
          break;
        case 'mc':
          setPType('MChrt');
          break;
        case 'nc':
          setPType('NChrt');
          break;
        case 'p3mar':
          setPlAspn('');
          setPType('P3Mar');
          break;
        case 'p3ven':
          setPlAspn('');
          setPType('P3Ven');
          break;
        case 'p3mer':
          setPlAspn('');
          setPType('P3Mer');
          break;
        case 'p3all':
          setPlAspn('');
          setPType('P3All');
          break;
        case 'p32p3':
          setPType('P3Ang');
          break;
        default:
          pType = '';
      }

      setPlAspp(pAbr[st.pevts[st.pidx].p]);

      if (st.pevts[st.pidx].a === 99) {
         setPlAspa("Sgn");
         setPlAspn(signs[st.pevts[st.pidx].n]);
         setPlAspnT("translate(35, 20) scale(.8)");
      } else if (st.pevts[st.pidx].a === 98) {
         setPlAspa("");
         setPlAspn("");
         setPdays("");
         setPEvent("");
         setPlAspp("");
         setPlAspa("");
         setPlAspn("");
         setPlAspnT("translate(35, 20) scale(.8)");

      } else {
         setPlAspa(a_names[st.pevts[st.pidx].a]);
         setPlAspn(pAbr[st.pevts[st.pidx].n]);
         setPlAspnT("translate(40, 30) scale(.28)");
      }
    }
    forceUpdateComponent();
  }
  }, [st.plng]);

  // timeline change
  const tlineChange = e => {
    st.tl_sel = e.target.value;
    st.pidx = 0;

    // ptype defaults to transits for
    if (st.ptype === '' || st.ptype === 'na') {
      st.ptype = "tr";
    }
    set_def_dts();
    setDefval(st.tl_sel);
    setPtype(st.ptype);
    setPevtsCur(0);
    setTabIndex(0);
    setPEvent("");
    setAsp_dt("");

    let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;
    sendReq({ msg: req });
    setIsOpen(false);
  }

  // modal pred type selection
  const ptypeChange = e => {
    setPtype(e.target.id);
  }

  const handleVCRClick = (e) => {
    if (st.id === '' ) {
      return;
    }
    if ( e === 'gear01'){
      if (st.id > 0) {
	 if (document.getElementById('gear').src == burl + geargrey) {
            return;
         } else {
	document.getElementById('gear').src = geargold;
	document.getElementById('heart').src = heartgrey;
        document.getElementById("combonme").innerHTML = "";
        openModal();
      }}
    } else {
    if ( e === 'heart01'){
      if (st.id > 0) {
	 if (document.getElementById('heart').src == burl + heartred) {
	    document.getElementById('heart').src = heartgrey;
	    document.getElementById('gear').src = geargold;
            document.getElementById("combonme").innerHTML = "";
            let req = `"{"event":"chart-req","data":"{'id': ${st.id}}"}"`;
            sendReq({ msg: req });
            return;
         } else {
         document.getElementById("inp01").value = "";
         document.getElementById("inp01").focus();
	 document.getElementById('heart').src = heartred;
         document.getElementById('gear').src = geargrey;
      }}
    } else {
      if ( st.ptype === 'na' ) {
        return;
      }
      let tmpECur = pevtsCur;

      switch(e) {
        case 'btnfwd' :
          tmpECur++;
          if (tmpECur > pevtsMax) {
            setPevtsCur(pevtsMax);
            return;
          }
          break;
        case 'btnbwd' :
          tmpECur--;
          if (tmpECur < 0) {
            setPevtsCur(0);
            return;
          }
          break;
        case 'btnbwdmax' :
          tmpECur = 0;
          break;
        case 'btnfwdmax' :
          tmpECur = pevtsMax;
          break;
        default:
          break;
      }
      setPevtsCur(tmpECur);

      // fall thru and reposition
      const req = `"{"event":"plng-req","data":"{'id': ${st.id}, 'jd': ${st.pevts[tmpECur].j}, 'ptype': '${st.ptype}', 'pidx': ${tmpECur} }"}"`;
      sendReq({ msg: req });
    }}
  };

  // pred Modal
  const openModal = () => {
    mod_ptype.current = (st.ptype.length > 0) ? st.ptype : 'tr';
    if (mod_ptype.current === 'na') {
      mod_ptype.current = 'tr';
      set_def_dts();
      fromdt = st.fromdt;
      todt = st.todt;
    }

    setIsOpen(true);
    setPtype(mod_ptype.current);
    setPdays('');
    messageRef.current = '';


    const today = new Date();

    if (!fromdt) {
      const [yyyy, mm, dd] = yyyymmdd(today);
      setFromdt(`${yyyy}-${mm}-${dd}`);
    }
    if (!todt) {
      const [yyyy2, mm2, dd2] = yyyymmdd(addMonths(today,12));
      setTodt(`${yyyy2}-${mm2}-${dd2}`);
    }
  }

  const cancelModal = (e) => {
    e.preventDefault();
    setIsOpen(false);
    messageRef.current = '';
  }

  const submitModal = (e) => {
    e.preventDefault();
    setPlAspp('');
    setPlAspa('');
    setPlAspn('');
    setPEvent('');
    setPdays('');
    messageRef.current = '';

    if (valDt(fromdt) === '') {
      messageRef.current = 'Invalid From Date';
      return;
    }
    if (ptype === 'tr100') {
        setIsOpen(false);
        setTabIndex(0);

        let dtf0 = new Date(fromdt + 'T12:00:00');
        let dtt0 = new Date(fromdt + 'T12:00:00');
        dtf0.setDate(dtf0.getDate());
        dtt0.setDate(dtt0.getDate() + 100);

        st.from = `${dtf0.getFullYear()}-${dtf0.getMonth()+1}-${dtf0.getDate()}`;
        st.to = `${dtt0.getFullYear()}-${dtt0.getMonth()+1}-${dtt0.getDate()}`;

        setPevtsCur(0);
        let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':1, 'ptype':'tr', 'pidx':${pevtsCur}, 'tl_sel':'${st.tl_sel}'}"}"`;
        sendReq({ msg: req });
	setIsOpen(false);
	return;
    }

    if (valDt(todt) === '') {
      messageRef.current = 'Invalid To Date';
      return;
    }

    let dtf = new Date(fromdt).getTime();
    let dtt = new Date(todt).getTime();
    let dtmin = new Date("01/01/1700").getTime();
    let dtmax = new Date("01/01/2100").getTime();
    let Difference_In_Days = (dtt - dtf) / (1000 * 3600 * 24);

    if ( Difference_In_Days <= 0) {
      messageRef.current = 'From Date must be less than To Date';
      return;
    }

    if ( dtt > dtmax ) {
      messageRef.current = 'Max Date 01/01/2100';
      return;
    }

    // to avoid race cond
    let days = pdays;

    if (!pdays) {
        days = 0;
    } else {
      setPdays(parseInt(days));
      if ( Difference_In_Days > (100 * days)) {
        messageRef.current = '100 Events max';
        return;
      }
    }

    if (Difference_In_Days > (365.25 * 5) && days === 0 &&
       (ptype !== 'mp' && ptype !== 'p3mer' && ptype !== 'p3ven' && ptype !== 'p3mar')) {
        messageRef.current = '5 Years max';
      return;
    }

    setIsOpen(false);

    let req = '';
    let dtf0 = new Date(fromdt + 'T12:00:00');
    let dtt0 = new Date(todt + 'T12:00:00');
    st.from = `${dtf0.getFullYear()}-${dtf0.getMonth()+1}-${dtf0.getDate()}`;
    st.to = `${dtt0.getFullYear()}-${dtt0.getMonth()+1}-${dtt0.getDate()}`;

    setTabIndex(0);

    if (ptype === 'na') {
      req = `"{"event":"chart-req","data":"{'id': ${st.id}}"}"`;
    } else {
      if (ptype === 'mc') {
      req = `"{"event":"mchart-req","data":"{'id': ${st.id}}"}"`;
    } else {
      if (ptype === 'nc') {
      req = `"{"event":"nchart-req","data":"{'id': ${st.id}}"}"`;
    } else {
      if ( ptype === 'p3mer') {
        setPevtsCur(0);
        setPdays('');
        req = `"{"event":"p3-planet-req","data":"{'id': ${st.id}, 'pl': 2, 'pidx': 0 }"}"`;
    } else {
      if ( ptype === 'p3ven') {
        setPevtsCur(0);
        setPdays('');
        req = `"{"event":"p3-planet-req","data":"{'id': ${st.id}, 'pl': 3, 'pidx': 0 }"}"`;    } else {
      if ( ptype === 'p3mar') {
        setPevtsCur(0);
        setPdays('');
        req = `"{"event":"p3-planet-req","data":"{'id': ${st.id}, 'pl': 4, 'pidx': 0 }"}"`;
    } else {
      if ( ptype === 'p3all') {
        setPevtsCur(0);
        setPdays('');
        req = `"{"event":"p3-all-req","data":"{'id': ${st.id}, 'from': '${st.from}', 'to': '${st.to}'}"}"`;
    } else {
        setPevtsCur(0);
        req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':${days}, 'ptype':'${ptype}', 'pidx':${pevtsCur}, 'tl_sel':'${st.tl_sel}'}"}"`;
      }}}}}}}
      sendReq({ msg: req });
      setIsOpen(false);
  }

  const fromdt_chg = (e) => {
    setFromdt(e.target.value);
  }
  const todt_chg = (e) => {
    setTodt(e.target.value);
  }
  const days_chg = (e) => {
    setPdays(e.target.value);
  }

  // https://www.robinwieruch.de/react-svg-icon-components
  // https://react-svgr.com/playground/
  // return position in wheel
  const inWheel = (xCtr, yCtr, radius, angDeg) => {
     const pos = [];
     const angRads = angDeg * (Math.PI / 180);
     pos.push(xCtr + radius * Math.cos(angRads));
     pos.push(yCtr + radius * Math.sin(angRads));
     return pos;
  }

  const onLookup = (lookup) => {
    if (lookup.name.length > 0) {
      let req = `"{"event":"chart-req","data":"{'id': ${lookup.id}}"}"`;
      const curheart = burl + heartred;
      if (document.getElementById('heart').src == curheart) {
         req = `"{"event":"combo-req","data":"{'id': ${lookup.id}}"}"`;
	 document.getElementById('inp01').value = st.name;
      }
      document.getElementById('inp01').value = st.name;
      sendReq({ msg: req });
      setPdays("");
      setPlAspp("");
      setPlAspa("");
      setPlAspn("");
      setPEvent("");
      setAsp_dt("");
    }

    st.lookups = [];
  };

  const onSearchName = (e) => {
    e.preventDefault();

    if (e.target.value) {
      let lsReg = localStorage.getItem('sgaRegister');
      const req = `"{"event":"lookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}','updt': false}"}"`;
      document.getElementById('inp01').value = e.target.value;
      sendReq({ msg: req });
    } else {
      document.getElementById('inp01').value = "";
      st.lookups = [];
    }
  };

  const onTabSelect = (tab) => {
    if (!st.id) {
      return;
    }
    if (st.tl_sel.length > 0 && tab !== 0) {
      return;
    }
    if (st.ptype === 'mp' ) {
      return;
    }
    if (st.from.length === 0) {
        set_def_dts();
    }
    switch(tab) {
      case 0:
        setTabIndex(tab);
        break;
      case 1:
        setTabIndex(tab);
        if (st.ptype === 'p3mer' || st.ptype === 'p3ven' || st.ptype === 'p3mar' || st.ptype === 'p3all') {
          let pevts = JSON.stringify(st.pevts);
          const pevts2= pevts.replace(/"/g,"'");
          const req = `"{"event":"p3-interps-req","data":" ${pevts2}"}"`;
          sendReq({ msg: req });
        } else {
           if (st.ptype === 'na') {
            set_def_dts();
            const req = `"{"event":"finterps-req","data":"{'id': ${st.id}, 'from': '${st.from}', 'to': '${st.to}', 'days':${st.days}, 'ptype': '${st.ptype}' }"}"`;
             sendReq({ msg: req });
	         } else {
                    if (document.getElementById('heart').src == burl + heartred) {
                       const req = `"{"event":"combointerps-req","data":"{'id': ${st.id}, 'from': '${st.from}', 'to': '${st.to}', 'days':${st.days}, 'ptype': '${st.ptype}' }"}"`;
                       sendReq({ msg: req });
	            } else {
            if (st.ptype !== 'mp' ) {
              const req = `"{"event":"interps-req","data":"{'id': ${st.id}, 'from': '${st.from}', 'to': '${st.to}', 'days':${st.days}, 'ptype': '${st.ptype}' }"}"`;
              sendReq({ msg: req });
           }
          }
	  }
        }
        break;
      case 2:
        if (st.ptype !== 'na' &&
            st.ptype !== 'lp' &&
            st.ptype !== 'mp' &&
            st.ptype !== 'p3mar' &&
            st.ptype !== 'p3ven' &&
            st.ptype !== 'p3mer'  &&
            st.ptype !== 'p3all' ) {
            setTabIndex(2);
            const req = `"{"event":"tline-req","data":"{'id': ${st.id}, 'from': '${st.from}', 'to': '${st.to}', 'days':${st.days}, 'ptype': '${st.ptype}' }"}"`;
            sendReq({ msg: req });
          }
        break;
      default:
        break;
    }
  };

  const set_def_dts = () => {
    let dtf0 = new Date();
    let dtt0 = new Date();
    st.from = `${dtf0.getFullYear()}-${dtf0.getMonth()+1}-${dtf0.getDate()}`;
    st.to = `${dtt0.getFullYear()+1}-${dtt0.getMonth()+1}-${dtt0.getDate()}`;
  }
  //const { innerWidth: width, innerHeight: height } = window;
  let modalStyle = (window.innerWidth > 375) ? modalBig : modalSmall;
  
  return (
    <div className='gr-container'>
    <div>
      <Modal isOpen={isOpen} style={modalStyle}>
      <fieldset>
    <legend className="pred-legend">Prediction Types</legend>

  <div className="pred-parent">
    <div>
     <input type="radio" name="pt" id="na" onChange={ptypeChange}/>
     <label htmlFor="na">Natal</label>
    </div>
    <div>
     <input type="radio" name="pt" id="tr" onChange={ptypeChange}/>
     <label htmlFor="tr">Transits</label>
    </div>
    <div>
     <input type="radio" name="pt" id="tr100" onChange={ptypeChange}/>
     <label htmlFor="tr100">100 Days</label>
    </div>
    <div>
    <input type="radio" name="pt" id="lp" onChange={ptypeChange}/>
    <label htmlFor="lp">Lunations</label>
    </div>
    <div>
       <input type="radio" name="pt" id="p2" onChange={ptypeChange}/>
       <label htmlFor="p2">SecProgs</label>
    </div>
    <div>
     <input type="radio" name="pt" id="sa" onChange={ptypeChange}/>
     <label htmlFor="sa">Solar Arcs</label>
    </div>
    <div>
     <input type="radio" name="pt" id="mc" onChange={ptypeChange}/>
     <label htmlFor="mc">MChrt</label>
    </div>
    <div>
     <input type="radio" name="pt" id="nc" onChange={ptypeChange}/>
     <label htmlFor="nc">NChrt</label>
    </div>
    { (st.tl_sel === '')  &&
    <>
    <div>
    <input type="radio" name="pt" id="p3" onChange={ptypeChange}/>
    <label htmlFor="p3">Tertiaries</label>
    </div>

    <div>
       <input type="radio" name="pt" id="mp" onChange={ptypeChange}/>
    <label htmlFor="mp">S2 Moon</label>
    </div>
    <div>
       <input type="radio" name="pt" id="p3mer" onChange={ptypeChange}/>
    <label htmlFor="p3mer">P3 Mer</label>
    </div>
    <div>
       <input type="radio" name="pt" id="p3ven" onChange={ptypeChange}/>
    <label htmlFor="p3ven">P3 Ven</label>
    </div>
    <div>
       <input type="radio" name="pt" id="p3mar" onChange={ptypeChange}/>
    <label htmlFor="p3mar">P3 Mar</label>
    </div>
    <div>
       <input type="radio" name="t2t" id="t2t" onChange={ptypeChange}/>
    <label htmlFor="t2t">Tr2Tr</label>
    </div>
    </>
    }
    </div>
    </fieldset>
    <div className ='dte-parent'>
     <div>
      <label className="dte-label" htmlFor="fdt">From</label>
      <input type="text" id="fdt" name="fdt" value={fromdt} onChange={fromdt_chg}/>
      </div>
    <div>
      <label className="dte-label" htmlFor="tdt">To</label>
      <input type="text" id="tdt" name="tdt" value={todt} onChange={todt_chg}/>
      </div>
    <div>
      <label  className="dte-label" htmlFor="dys">Days</label>
      <input type="text" id="dys" name="dys" value={pdays} maxLength="5" size="5" onChange={days_chg}/>
      </div>
    </div>
    <div className="col text-center">
        <span className='emsg'>{messageRef.current}</span>
    </div>
    <div className='btnctr'>
      <button className="pred-btn" type="submit" onClick={submitModal}>Submit</button>&emsp;
      <button className="pred-btn" type="reset"  onClick={cancelModal}>Cancel</button>
    </div>

      </Modal>

    {/* client lookup input */}
    <div className="lu01">
      <input autoFocus maxLength = "30" className="inp01" id="inp01"
        type="text" autocomplete="off"
        onChange={onSearchName} ref={clientnameRef}
        placeholder="Chart name..." />

      {st.tlines && st.tlines.length > 0 &&
        <select value={defval} onChange={tlineChange}>
         <option key={uuidv4()} value=""></option>
         {opts}
        </select>
      }
      <span className="topbtns" style={{width: '5px'}}>&nbsp;&nbsp;</span>
      <span className="topbtns"
          onClick={() => handleVCRClick('heart01') }>
          <img id='heart' src={heartgrey}/>
      </span>
      <span className="topbtns"
          onClick={() => handleVCRClick('gear01') }>
          <img id='gear' src={geargold}/>
      </span>
      <div>
        <p id='combonme' style={{fontSize: '15px', fontiFmily: 'Marcellus', margin: '0px'}}></p>
      </div>
    </div>

    {/* client lookup candidates */}
    <div className="lu02" id="lookups">
      <ul className="dd01">
        {""}
        {st.lookups.map((lookup, index) => (
          <li onClick={() => onLookup(lookup)} key={lookup.id}>
            {""}
            {lookup.name}
          </li>
        ))}
      </ul>
    </div>

    <div>
      <ul className="dd01">
        {""}
        { st.lng.map((lng, index) => (
          <li key={uuidv4()}></li>))
        }
      </ul>
    </div>
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 440 440" >
      <defs>
        <filter id="blur">
          <feGaussianBlur stdDeviation="3" />
          <feDropShadow dx="7.4" dy="7.4"
            floodColor="rgb(80,80,80)" floodOpacity="0.5"
            stdDeviation="3"/>
        </filter>
      </defs>

      <g id="mainwheel">
        <circle fill="white"
          stroke="#000000" strokeWidth="2"
          r={rad} cx={xctr} cy={yctr}
          style={ {filter: 'url(#blur)'} } />
        <circle fill="white" stroke="#000000"
          strokeWidth="2"
          cx={xctr} cy={yctr} r={rad}/>
        <circle fill="white" stroke="#2c5aa0"
          strokeWidth="3"
          cx={xctr} cy={yctr} r="160"/>
      </g>
      <g id="hcusps" transform={wrot} >
        {hsex1.map((ll,ix) =>
          <line stroke="#000000"
            strokeWidth="1" key={uuidv4()} x1={hsex1[ix]} y1={hsey1[ix]} x2={hsex2[ix]} y2={hsey2[ix]} />
        )}
        <line stroke="#000000"
          strokeWidth="2.3" key='mc4'
          x1={hsex1[4]} y1={hsey1[4]} x2={hsex2[4]} y2={hsey2[4]}/>

        <circle stroke="#000000" key={uuidv4()}
          strokeWidth="1" fill="white" cx={xctr}
          cy={yctr} r="60"/>
      </g>

      {hse_glyph.map((hse, index) =>
        <Icon key={uuidv4()} name={hse}
          transf={hse_pos[index]}/>
      )}

      {/* nat planet position */}
      {pl_dsm.map((abr, ix) =>
        <Icon key={uuidv4()} name={pAbr[pord[ix]]}
          transf={pl_pos[ix]} rtro={pl_rtro[ix]} oob={pl_decl[ix]}/>)}

      {/* nat planet sign */}
      {pl_dsm.map((sdm, ix) =>
        <Icon key={uuidv4()} name={signs[sdm[0]]}
          transf={pl_pos_glyph[ix]} /> )}

      {/* pred planet position */}
      {ppl_dsm.map((abr, ix) =>
        <Icon key={uuidv4()} name={pAbr[ppord[ix]]}
          transf={ppl_pos[ix]} rtro={ppl_rtro[ix]} oob={ppl_decl[ix]}/>)}

      <g id="plhs_dm" fontFamily="Ariel" fontSize="12">
        {hse_pos_deg.map((deg, ix) =>
          <text key={uuidv4()} transform={deg}>
          {hse_deg[ix]}</text> )}
        {hse_pos_min.map((min, ix) =>
          <text key={uuidv4()} transform={min}>
            {hse_min[ix]}</text> )}
        {pl_pos_deg.map((deg, ix) =>
          <text key={uuidv4()} transform={deg}>
            {nat_deg[ix]}</text> )}
        {pl_pos_min.map((min, ix) =>
          <text key={uuidv4()} transform={min}>
            {nat_min[ix]}</text> )}
      </g>
      <g id="pp_dm" fontFamily="Ariel" fontSize="12">
        {ppl_pos_deg.map((hh, ix) =>
          <text key={uuidv4()} transform={ppl_pos_deg[ppord[ix]]}>
          {pp_degmin[ppord[ix]]}</text> )}
      </g>
      <g id="evt_info" fontFamily="Roboto" fontSize="14">
        <text id="pred_dt" textAnchor="start" transform={dtepos}>{asp_dt}</text>
        <text id="pred_type" textAnchor="end" transform='translate(427, 15)'>{pType}</text>
        { (sunh60 > -1) &&
          <>
	        <Icon key='999' name={signs[sunh60]}
          transf='translate(382, 5) scale(0.8)' />
	        <Icon key='998' name={signs[sunh360]}
          transf='translate(402, 5) scale(0.8)' />
          <Icon key='997' name={pAbr[0]}
          transf='translate(367, 13.5) scale(0.3)' />
          </>
        }
        { (pLPhase && pLPhase.length > 0) &&
          <>
        <Icon key='996' name={pAbr[1]}
          transf='translate(367, 30) scale(0.3)' />
        <text id="lun_ph" textAnchor="end" transform='translate(409, 45)'>{pLPhase} </text>
        <Icon key='995' name={signs[prg_hse]}
          transf='translate(402, 20.5) scale(0.8)'/>
          </>
        }
        { !tlind &&
          <>
        <text id="pred_days" textAnchor="start" transform='translate(10, 27)'>{pdays}</text>
        <Icon name={plAspp}
          transf='translate(10, 30) scale(.28)'/>
        <Icon name={plAspa}
          transf='translate(25, 30) scale(.6)'/>
        <Icon name={plAspn} transf={plAspnT}/>
        </>
        }
        <text id='pevt' textAnchor="start" transform={evtpos}>{pEvent}</text>
        </g>

        <g transform="translate(382,390)"
          onClick={() => handleVCRClick('btnfwdmax') }>
          <IconVCRFMax vcrfill="#298F68"/>
        </g>

        <g transform="translate(15,390)"
          onClick={() => handleVCRClick('btnfwd') }>
            <IconVCRF vcrfill="#298F68"/>
        </g>

        <g transform="translate(382,345)"
          onClick={() => handleVCRClick('btnbwdmax') }>
            <IconVCRBMax vcrfill="#786BCD"/>
        </g>

        <g transform="translate(15,345)"
          onClick={() => handleVCRClick('btnbwd') }>
          <IconVCRB vcrfill="#786BCD"/>
        </g>

    </svg>
    </div>
    <div className='tabcol' style={{fontSize:'18px'}}>
      <Tabs className="react-tabs" selectedIndex={tabIndex} onSelect={ix => onTabSelect(ix)}>
        <TabList className="react-tabs__tab-list">
          {<Tab className="react-tabs__tab">Aspects</Tab>}
          {<Tab className="react-tabs__tab">Interps</Tab>}
          {<Tab className="react-tabs__tab">Timeline</Tab>}
        </TabList>
        {<TabPanel className="react-tabs__tab-panel"><Aspects /></TabPanel>}
        {<TabPanel className="react-tabs__tab-panel"><Interps/></TabPanel>}
        {<TabPanel className="react-tabs__tab-panel"><Timeline/></TabPanel>}
      </Tabs>
    </div>
  </div>
  )
}
