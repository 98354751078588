import React from "react";
import * as FaIcons from "react-icons/fa";
import * as AiIcons from "react-icons/ai";
import * as IoIcons from "react-icons/io";

export const SidebarData = [
  {
    title: 'Home',
    path: '/',
    icon: <AiIcons.AiFillHome/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Book Reading',
    path: '/bookreading',
    icon: <IoIcons.IoIosPaper/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Charts',
    path: '/charts',
    icon: <FaIcons.FaStar/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Sun & Moon',
    path: '/sunmoon',
    icon: <FaIcons.FaMoon/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Lunar Phases',
    path: '/lphases',
    icon: <FaIcons.FaMoon/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Maint Charts',
    path: '/newchart',
    icon: <IoIcons.IoIosPaper/>,
    cName: 'nav-text',
    pub_access: false
  },
  {
    title: 'Videos',
    path: '/ytvideos',
    icon: <IoIcons.IoIosVideocam/>,
    cName: 'nav-text',
    pub_access: false
  },
  {
    title: 'TL Edit',
    path: 'tledit',
    icon: <FaIcons.FaFilm/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Timelines',
    path: 'tlevents',
    icon: <FaIcons.FaFilm/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Booklines',
    path: 'tlbook',
    icon: <FaIcons.FaFilm/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'Sign up',
    path: '/register',
    icon: <FaIcons.FaAddressCard/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'LogIn',
    path: '/logIn',
    icon: <FaIcons.FaSignInAlt/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'How To',
    path: '/howto',
    icon: <FaIcons.FaQuestion/>,
    cName: 'nav-text',
    pub_access: true
  },
  {
    title: 'About',
    path: '/about',
    icon: <FaIcons.FaHatWizard/>,
    cName: 'nav-text',
    pub_access: true
  },
  /*
   * {
    title: 'Grid01',
    path: '/grid01',
    icon: <FaIcons.FaHatWizard/>,
    cName: 'nav-text'
  },
  */
];
