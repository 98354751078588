import React, { useState, useEffect } from "react";
import { CTX } from "../components/Store";
import Modal from "react-modal";
import { Icon3} from "./Icon";
import { v4 as uuidv4 } from 'uuid';

Modal.setAppElement('#root')

function TLBook() {
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  const [lookupname, setLookupname] = useState('');
  const [defval, setDefval] = useState("");
  const [opts, setOpts] = useState([]);
  const [ptype, setPtype] = useState('');
  const [pevtsCur, setPevtsCur] = useState(0);
  const [pEvent, setPEvent] = useState('');
  const [asp_dt, setAsp_dt] = useState('');

useEffect(() => {
    const req = "expinit";
    sendReq({ msg: req });
}, []);

useEffect(() => {
  if (st.name !== '') {
    setLookupname(st.name);
  }
  if(st.id){
    st.from = '2000-01-01';
    st.to = '2000-01-02';
   if (st.ptype === '' || st.ptype === 'na') {
      st.ptype = "p2";
    }

    let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

    sendReq({ msg: req });
  }
    if (st.tlines.length > 0) {
    let list = st.tlines.map((item, index) => {
      return (
        <option value={item}>{item}</option>
      )
    })
    setOpts(list);
    setDefval("");
  } else {
    setOpts([]);
  }
  forceUpdateComponent();
}, [st.lng]);

const onLookup = (lookup) => {
  if (lookup.name.length > 0) {
    const req = `"{"event":"chart-req","data":"{'id': ${lookup.id}}"}"`;
    sendReq({ msg: req });
  }
  setLookupname(lookup.name);
  st.lookups = [];
};

 const onSearchName = (e) => {
    e.preventDefault();

    if (e.target.value) {
      let lsReg = localStorage.getItem('sgaRegister');
      //const req = `"{"event":"explookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}'}"}"`;
      const req = `"{"event":"explookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}','updt': false}"}"`;
      setLookupname(e.target.value);
      sendReq({ msg: req });
    } else {
      setLookupname('');
      st.lookups = [];
    }
  };

const periodChg = e => {
    st.tl_sel = e.target.value;
    st.pidx = 0;

    if (st.ptype === '' || st.ptype === 'na') {
      st.ptype = 'p2';
    }
    st.from = '2000-01-01';
    st.to = '2000-01-02';
    setDefval(st.tl_sel);
    setPtype(st.ptype);
    setPevtsCur(0);
    setPEvent("");
    setAsp_dt("");

    let req = `"{"event":"expevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

    sendReq({ msg: req });
  }

  return (
    <>
    <div className="lu01">
      <input
        className="inp01" id="inp01" autocomplete="off"
        type="text" value={lookupname}
        onChange={onSearchName}
        placeholder="Chart name..." />

      {st.tlines && st.tlines.length > 0 &&
        <select value={defval} onChange={periodChg} id={uuidv4()}>
         <option selected value=""></option>
         {opts}
        </select>
      }
    </div>
    <div className="lu02" id="lookups">
      <ul className="dd01">
        {""}
        {st.lookups.map((lookup, index) => (
          <li onClick={() => onLookup(lookup)} key={index}>
            {""}
            {lookup.name}
          </li>
        ))}
      </ul>
    </div> 
    <div className="tlbook">
       {st.exp && st.exp.length > 0 &&  
       st.exp.map((evt,i) => (
	<>
          <div className="tlbook">
            <h4 className="bk01">{evt.dt} &nbsp; {evt.etitle}</h4>
	  </div>
          <div className="tlbook">
	      <img src="images/sunx.png" alt=''/>&nbsp;<strong>{evt.sundsm.deg}</strong>
	      <Icon3 name={evt.sundsm.sgn} transf="scale(2.2)"/><strong>{evt.sundsm.min}</strong> &nbsp; &nbsp;
	      <Icon3 name={evt.sun60} transf="scale(2.2)" fill={evt.h60color}/>/
	      <Icon3 name={evt.sun360} transf="scale(2.2)" fill={evt.h360color}/>&nbsp; &nbsp;
 	      <img src="images/monx.png" alt=''/>&nbsp;<strong>{evt.mondsm.deg}</strong>
	      <Icon3 name={evt.mondsm.sgn} transf="scale(2.2)"/><strong>{evt.mondsm.min}&nbsp;{evt.phase}</strong>&nbsp; &nbsp;
	      <strong>Asc&nbsp;{evt.ascdsm.deg}</strong>
	      <Icon3 name={evt.ascdsm.sgn} transf="scale(2.2)"/><strong>{evt.ascdsm.min}</strong>
	   </div>
	   <p> </p>
	 </>
        ))}
    </div>
   </>
  );
}

export default TLBook;

