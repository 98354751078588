import React, { useState, useEffect, useContext } from "react";
import { revjul } from './util';
import { CTX } from "../components/Store";
import { v4 as uuidv4 } from 'uuid';

const AccordionItem = ({ title, evtdts, content, jd}) => {
  const [isOpen, setIsOpen] = useState(false);
  const toggleAccordion = () => {
    setIsOpen(!isOpen);
  };

  //{evtdts.map((evt,index) => <p className='evtdates' key={uuidv4()}>{evt}</p>)}
  //<p className='evtdates'>{evtdts}</p>
  return (
   <div className="accordion-item">
     <div className="accordion-title" onClick={toggleAccordion}>
        {title}
     </div>
     {isOpen && <div key={uuidv4()} className="accordion-content">
        {evtdts.map((evt,index) => <span className='evtdates' key={uuidv4()}>{evt}<br/></span>)}
	{content.split("~").map((item,index) => <p className='interp' key={index}>{item}</p>)}
     </div>}
   </div>
 );
};

// <h1 style={{textAlign: 'center'}}>The 28 Lunar Phases</h1>
const AccordionList = ({ items, itype, ifrom }) => {
    let ititle = "";
    for (let i = 0; i < items.length; i++) {
    //    console.log("AItems1", i, items[i].interp);
	items[i].interp = items[i].interp.replaceAll("`", "'");
    //    console.log("AItems22, i, items[i].interp);
    }
    switch (itype) {
      case 'na': 
        ititle = "Natal Interpretations";
        break;
      case 'tr': 
        ititle = "Transit Predictions";
	if(ifrom.includes(",")) {
          ititle = "Synastry Interpretations";
	}
        break;
      case 'p2': 
        ititle = "Secondary Predictions";
        break;
      case 'p3': 
        ititle = "Tertiary Predictions";
        break;
      case 'sa': 
        ititle = "Solar Arc Predictions";
        break;
      case 'tr100': 
        ititle = "100 Days Predictions";
        break;
      case 'lp': 
        ititle = "Lunations Predictions";
        break;
      case 'p3mer': 
        ititle = "Tertiary Mercury";
        break;
      case 'p3ven': 
        ititle = "Tertiary Venus";
        break;
      case 'p3mar': 
        ititle = "Tertiary Mars";
        break;
    }
		
  return (
     <div className="accordion-list">
       <h1 style={{textAlign: 'center'}}>{ititle}</h1>
       {items.map((item, index) => (
         <AccordionItem
           key={index}
           title={item.title}
           evtdts={item.evts}
           content={item.interp}
           jd={item.jd}
         />
       ))}
     </div>
  );
};
export default function Interps() {
  const { st, sendReq } = useContext(CTX);
  const [, updateComponent] = React.useState();
  const [titlefmt, setTitlefmt] = useState('');
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), []);

  const [pdates] = useState([]);
  const [ilines, setIlines] = useState([]);
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    if (st.interps && st.interps.length > 0  ) {
      if (st.ptype === 'na' || st.ptype === 'lp' ){
        setTitlefmt('');
      } else {
        setTitlefmt('  ');
      }
      for (let i =0; i < st.interps.length; i++ ) {
    	let sep = " - ";
        if (st.interps[i].title.startsWith("Moon Phase") ||
            st.interps[i].title.startsWith("Progressed Sun") ||
            st.ptype === 'na' || st.ptype === 'lp' ||
	    st.from.includes(",")) {
            sep = "";
	}  
        if (st.interps[i].title.startsWith("Moon Phase") ||
            st.interps[i].title.startsWith("Progressed Sun")) {
            st.interps[i].pdate = "";
            st.interps[i].days = "";            
	}
        if (st.interps[i].title.startsWith("Progressed Sun")) {
            st.interps[i].title = st.interps[i].title + ' ' +  revjul(st.interps[i].jd);
        } else {
            st.interps[i].title = st.interps[i].title + sep + st.interps[i].days;
        }
      }
    }
    forceUpdateComponent();
  }, [st.interps]);

  return (
     <AccordionList items={st.interps} itype={st.ptype } ifrom={st.from} />
  );
}
