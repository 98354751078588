import React, { useState, useEffect } from "react";
import { CTX } from "../components/Store";
import Modal from "react-modal";
import { VerticalTimeline, VerticalTimelineElement, } from "react-vertical-timeline-component";
import "react-vertical-timeline-component/style.min.css";
import { deg2Sgn } from './util';
import { Icon3 } from "./Icon";
import { v4 as uuidv4 } from 'uuid';
import { SVGComponent } from "../components/SVGComponent";

Modal.setAppElement('#root')

function TLEvents() {
  const { st, sendReq } = React.useContext(CTX);
  const [, updateComponent] = React.useState();
  const forceUpdateComponent = React.useCallback(() => updateComponent({}), [])
  let [lookupname, setLookupname] = useState('');
  const [defval, setDefval] = useState("");
  const [opts, setOpts] = useState([]);

useEffect(() => {
    const req = "expinit";
    sendReq({ msg: req });
}, []);

useEffect(() => {
  if (st.name !== '') {
    setLookupname(st.name);
  }
  if(st.id){
    st.from = '2000-01-01';
    st.to = '2000-01-02';
   if (st.ptype === '' || st.ptype === 'na') {
      st.ptype = "p2";
    }

    let req = `"{"event":"ipevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

    sendReq({ msg: req });
  }
    if (st.tlines.length > 0) {
    let list = st.tlines.map((item, index) => {
      return (
        <option value={item}>{item}</option>
      )
    })
    setOpts(list);
    setDefval("");
  } else {
    setOpts([]);
  }
  forceUpdateComponent();
}, [st.lng]);

const onLookup = (lookup) => {
  if (lookup.name.length > 0) {
    const req = `"{"event":"chart-req","data":"{'id': ${lookup.id}}"}"`;
    sendReq({ msg: req });
  }
  setLookupname(lookup.name);
  st.lookups = [];
};

 const onSearchName = (e) => {
    e.preventDefault();

    if (e.target.value) {
      let lsReg = localStorage.getItem('sgaRegister');
      //const req = `"{"event":"explookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}'}"}"`;
      const req = `"{"event":"explookup-req","data":"{'srch': '${e.target.value}','auth': '${lsReg}','updt': false}"}"`;
      setLookupname(e.target.value);
      sendReq({ msg: req });
    } else {
      setLookupname('');
      st.lookups = [];
    }
  };

const periodChg = e => {
    st.tl_sel = e.target.value;
    st.pidx = 0;

    if (st.ptype === '' || st.ptype === 'na') {
      st.ptype = 'p2';
    }
    st.from = '2000-01-01';
    st.to = '2000-01-02';
    setDefval(st.tl_sel);
    let req = `"{"event":"expevts-req","data":"{'id': ${st.id}, 'from':'${st.from}', 'to':'${st.to}', 'days':0, 'ptype':'${st.ptype}', 'pidx':${st.pidx}, 'tl_sel':'${st.tl_sel}'}"}"`;

    sendReq({ msg: req });
  }

  return (
    <>
    <div className="lu01">
      <input className="inp01" id="inp01"
        type="text" value={lookupname}
        onChange={onSearchName}
        placeholder="Chart name..." />

      {st.tlines && st.tlines.length > 0 &&
        <select value={defval} onChange={periodChg} id={uuidv4()} >
         <option selected value=""></option>
         {opts}
        </select>
      }
    </div>
    <div className="lu02" id="lookups">
      <ul className="dd01">
        {""}
        {st.lookups.map((lookup, index) => (
          <li onClick={() => onLookup(lookup)} key={index}>
            {""}
            {lookup.name}
          </li>
        ))}
      </ul>
    </div> 
    <div className="experience">
       {st.exp && st.exp.length > 0 &&  
      <VerticalTimeline lineColor="#3e497a">
       {st.exp.map((evt,i) => (
	<>
          <VerticalTimelineElement style={{margin: "0px", padding: "0px", lineHeight: "1"}}
            className="expdt vertical-timeline-element--education"
            iconStyle={{ background: `${evt.h60color}`, color: "#fff", stroke: "none" }}
            icon={<SVGComponent nbr={evt.sundsm.ph}/>}
          >
          <h3 className="vertical-timeline-element-title title-tline">{evt.dt} &nbsp; {evt.etitle}</h3>
          <div className="expdeg" style={{fontSize: "1em"}}>
	    <div className="smasc">
	      <img src="images/sunx.png" alt=''/>&nbsp;{evt.sundsm.deg}
	      <Icon3 name={evt.sundsm.sgn} transf="scale(2.2)"/>{evt.sundsm.min} &nbsp;
	      <Icon3 name={evt.sun60} transf="scale(2.2)" fill={evt.h60color}/>/
	      <Icon3 name={evt.sun360} transf="scale(2.2)" fill={evt.h360color}/>
	     </div>
	     <div className="smasc">
 	      <img src="images/monx.png" alt='' />&nbsp;{evt.mondsm.deg}
	      <Icon3 name={evt.mondsm.sgn} transf="scale(2.2)"/>{evt.mondsm.min}&nbsp;{evt.phase}
	     </div>
	     <div className="smasc">
	      <strong>Asc</strong>&nbsp;{evt.ascdsm.deg}
	      <Icon3 name={evt.ascdsm.sgn} transf="scale(2.2)"/>{evt.ascdsm.min}
	     </div>
	  </div>
          </VerticalTimelineElement>
	 </>
        ))}
        </VerticalTimeline>
        }
    </div>
   </>
  );
}

export default TLEvents;

